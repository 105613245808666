import { render, staticRenderFns } from "./MobileNavBar.vue?vue&type=template&id=023fe26a&scoped=true&"
import script from "./MobileNavBar.vue?vue&type=script&lang=ts&"
export * from "./MobileNavBar.vue?vue&type=script&lang=ts&"
import style0 from "./MobileNavBar.vue?vue&type=style&index=0&id=023fe26a&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023fe26a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VNavigationDrawer } from 'vuetify/lib'
installComponents(component, {VContainer,VFlex,VLayout,VNavigationDrawer})
