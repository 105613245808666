import { render, staticRenderFns } from "./ContentThin.vue?vue&type=template&id=46c0cc8d&scoped=true&"
import script from "./ContentThin.vue?vue&type=script&lang=ts&"
export * from "./ContentThin.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c0cc8d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VFlex,VLayout})
