















import MobileNavBar from '@/components/elements/MobileNavBar.vue';
import NavRouterLinks from '@/components/elements/NavRouterLinks.vue';
import PageFooter from '@/components/elements/PageFooter.vue';
import ScrollUpNavi from '@/components/elements/ScrollUpNavi.vue';
import {Component, Prop, Vue} from "vue-property-decorator";
import UnderConstructionContent from '@/components/contents/UnderConstructionContent.vue';
@Component({
             components: {
               MobileNavBar,
               NavRouterLinks,
               ScrollUpNavi,
               UnderConstructionContent,
               PageFooter
             }
           })
export default class Page extends Vue {
  @Prop({default: 'page'}) protected pageKey!: string;
  @Prop({default: ''}) protected additionalClass!: string;
  @Prop({default: {menuActive:false}}) protected pageData!: object;

  get pageClass(): string {
    return this.pageKey + ' ' + this.additionalClass;
  }

}
