

import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class BaseSite extends Vue {
  protected gaProperty: string = 'UA-134769807-1';
  // Disable tracking if the opt-out cookie exists.
  public disableStr: string = 'ga-disable-' + this.gaProperty;


  created() {
    if (document.cookie.indexOf(this.disableStr + '=true') > -1) {
      window[this.disableStr] = true;
    }
  }

}
