










import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
             components: {}
           })
export default class ContentThin extends Vue {
  @Prop({default: ''}) protected classLeft!: string;
  @Prop({default: ''}) protected classCenter!: string;
  @Prop({default: ''}) protected classRight!: string;
}
