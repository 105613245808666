










import {Component, Vue} from "vue-property-decorator";

@Component({
           })
export default class NavRouterLinks extends Vue {
  private links: object[] = [
    {title:"Kontaktieren Sie uns", link:"kontakt", class:"kontakt nav-item-convert"},
    {title:"Shop Optimierung", link:"shop-optimierung", class:"optimierung"},
    {title:"Shop Entwicklung", link:"shop-entwicklung", class:"entwicklung"},
    ]
}
