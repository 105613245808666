



















import {Component, Vue} from "vue-property-decorator";
import ContentThin from "@/components/elements/ContentThin";

@Component({
             components: {
               ContentThin
             }
           })
export default class UnderConstructionContent extends Vue {
}
