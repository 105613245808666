






















import NavRouterLinks from '@/components/elements/NavRouterLinks.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {NavRouterLinks}
           })
export default class ScrollUpNavi extends Vue {
  @Prop({default:{menuActive:false}}) private pageData!: object;
  private limitPosition: number = 84;
  private scrolled: boolean = true;
  private lastPosition: number = 0;

  handleScroll() {
    if (window.scrollY > 300) {

      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }

      this.lastPosition = window.scrollY;
      // this.scrolled = !(window.scrollY > 250);
    } else {
      this.scrolled = true;
    }
  }

  created() {
    window.addEventListener("scroll", this.handleScroll);
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
