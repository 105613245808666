




































import NavRouterLinks from '@/components/elements/NavRouterLinks.vue';
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
             components: {NavRouterLinks}
           })
export default class MobileNavBar extends Vue {
  @Prop({default: {menuActive: false}}) protected pageData!: object;
  private maxWidth: number = window.screen.width;
  private maxHeight: number = window.screen.height;
  private top: number = 0;

  @Watch('pageData', {deep: true})
  private onPageDataChanged(val: {}, oldVal: {}) {
    this.top = window.scrollY;
  }
}
